import Cookies from "js-cookie";
import UsuarioQraphQL from "js/graphql/resolvers/usuario.resolver";
import client from "../App/client.graphql.js";
import _, { isEmpty, get } from "lodash";
import { getPrograma, getTheme } from "js/App/utils";
import TranslationService from "js/utils/TranslationService";
import AppQraphQL from "js/graphql/resolvers/programa.resolver";
import AccessQraphQL from "js/graphql/resolvers/access.resolver";
import uniq from "lodash/uniq";
import { sendTrackedSession } from "./TrackEventService.js";
export const COOKIE_NAME = "ashiwea.session";

export default class AuthService {
  static init = () => window.setInterval(AuthService.checkCookie, 3000);

  static checkCookie = (function() {
    let lastCookie = AuthService.getCookie();
    return function() {
      const currentCookie = AuthService.getCookie();
      if (currentCookie !== lastCookie) {
        lastCookie = currentCookie;
        if (!currentCookie) {
          AuthService.clearStorage();
          const pathname = window.location.pathname.includes("/admin")
            ? "/admin"
            : "/";
          window.location.pathname = pathname;
        }
      }
    };
  })();

  static clearStorage() {
    const idioma = TranslationService.getIdioma();
    const recordame = AuthService.getRecordame();
    const lastUsername = AuthService.getLastUsername();
    window.localStorage.clear();
    TranslationService.set(idioma);
    AuthService.setRecordame(recordame);
    AuthService.setLastUsername(lastUsername);
  }

  static getCookie() {
    return document.cookie.match(COOKIE_NAME);
  }

  static removeCookie() {
    return Cookies.remove(COOKIE_NAME, {
      path: "",
      domain: window.location.hostname
    });
  }

  static loggedIn(login = true) {
    return (!!AuthService.getCookie() && login) || false;
  }

  static async logout({ redirect }) {
    const event = new CustomEvent("logout", {});
    document.dispatchEvent(event);

    await client.query({
      refetchQueries: [`getRedirect`],
      query: UsuarioQraphQL.queries.logout,
      fetchPolicy: "no-cache",
      errorPolicy: "all"
    });

    AuthService.clearStorage();
    AuthService.removeCookie();
    if (redirect) AuthService.setUltimaURL(redirect);
    window.location.replace("/signin");
  }

  static setPrivacy(isPublic) {
    window.localStorage.setItem("isPublic", isPublic);
  }

  static setTieneMFA(tieneMFA) {
    window.localStorage.setItem("tieneMFA", tieneMFA);
  }

  static setIdPrograma(idPrograma) {
    window.localStorage.setItem("idPrograma", idPrograma);
  }

  static setUltimaURL(ultimaURL) {
    window.localStorage.setItem("ultimaURL", ultimaURL);
  }

  static getUltimaURL() {
    return window.localStorage.getItem("ultimaURL");
  }

  static setUltimaURLToTrack(ultimaURL) {
    window.localStorage.setItem("ultimaURLToTrack", ultimaURL);
  }

  static getUltimaURLToTrack() {
    return window.localStorage.getItem("ultimaURLToTrack");
  }

  static isPublic() {
    return JSON.parse(window.localStorage.getItem("isPublic"));
  }
  static getHtmlExpectativa() {
    return window.localStorage.getItem("htmlExpectativa");
  }
  static setHtmlExpectativa(htmlExpectativa) {
    return window.localStorage.setItem(
      "htmlExpectativa",
      htmlExpectativa ? htmlExpectativa : ""
    );
  }
  static setIdUsuario(id) {
    window.localStorage.setItem("idUsuario", id);
  }

  static getIdUsuario() {
    return window.localStorage.getItem("idUsuario");
  }

  static getIdPrograma() {
    return window.localStorage.getItem("idPrograma");
  }

  static setLoginTime(time) {
    window.localStorage.setItem("loginTime", time);
  }

  static getLoginTime() {
    return window.localStorage.getItem("loginTime");
  }

  static setRoles(roles) {
    window.localStorage.setItem("roles", JSON.stringify(uniq(roles || [])));
  }

  static getRoles() {
    return JSON.parse(window.localStorage.getItem("roles")) || [];
  }

  static setUserRoles(roles) {
    window.localStorage.setItem("userRoles", JSON.stringify(uniq(roles || [])));
  }

  static getUserRoles() {
    return JSON.parse(window.localStorage.getItem("userRoles")) || [];
  }

  static setIdSegmento(id) {
    window.localStorage.setItem("idSegmento", id);
  }

  static getIdSegmento() {
    return window.localStorage.getItem("idSegmento");
  }

  static setNombreSegmento(nombre) {
    window.localStorage.setItem("nombreSegmento", nombre);
  }

  static getNombreSegmento() {
    return window.localStorage.getItem("nombreSegmento");
  }

  static setPaisesPrograma(paises) {
    window.localStorage.setItem("paisesPrograma", JSON.stringify(paises));
  }

  static getPaisesPrograma() {
    return JSON.parse(window.localStorage.getItem("paisesPrograma")) || [];
  }

  static hasPerms(rolesPermitidos) {
    if (!AuthService.getRoles() || isEmpty(rolesPermitidos)) return false;
    const hasRoles = AuthService.getRoles().filter(function(e) {
      return this.indexOf(e) >= 0;
    }, rolesPermitidos);
    return hasRoles.length === rolesPermitidos.length;
  }

  static hasAnyPerm(rolesPermitidos) {
    if (!AuthService.getRoles() || isEmpty(rolesPermitidos)) return false;
    return !!AuthService.getRoles().some(e => rolesPermitidos.indexOf(e) >= 0);
  }

  static loadState = () => {
    try {
      const serializedState = window.localStorage.getItem("store");
      return serializedState === null ? undefined : JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  };

  static saveState = state => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem("store", serializedState);
    } catch (err) {}
  };

  static setLastUsername(username) {
    window.localStorage.setItem("lastUsername", username);
  }

  static getLastUsername() {
    return window.localStorage.getItem("lastUsername");
  }

  static setRecordame(recordame) {
    window.localStorage.setItem("recordame", recordame ? 1 : 0);
  }

  static getRecordame() {
    return parseInt(window.localStorage.getItem("recordame"));
  }

  static setTrackLinks(trackLinks) {
    window.localStorage.setItem("trackLinks", trackLinks ? 1 : 0);
  }

  static getTrackLinks() {
    return parseInt(window.localStorage.getItem("trackLinks"));
  }

  static setTrackearTodosLinks(trackTodos) {
    window.localStorage.setItem("trackearTodosLinks", trackTodos ? 1 : 0);
  }

  static getTrackearTodosLinks() {
    return window.localStorage.getItem("trackearTodosLinks") === "1"
      ? true
      : false;
  }

  static setTrackSession(trackSession) {
    window.localStorage.setItem("trackSession", trackSession ? 1 : 0);
  }

  static getTrackSession() {
    return window.localStorage.getItem("trackSession") === "1" ? true : false;
  }

  static setLinksToTrack(linksToTrack) {
    window.localStorage.setItem("linksToTrack", JSON.stringify(linksToTrack));
  }

  static getLinksToTrack() {
    return JSON.parse(window.localStorage.getItem("linksToTrack")) || [];
  }

  static login = (
    username,
    password,
    MFACode,
    idPrograma,
    updateTemplate,
    recordame,
    tieneMFA
  ) => {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: AccessQraphQL.queries.signin,
          errorPolicy: "all",
          fetchPolicy: "network-only",
          variables: {
            username,
            password,
            MFACode,
            idPrograma,
            tieneMFA
          }
        })
        .then(({ errors, data }) => {
          if (errors && errors.length > 0) reject(errors);
          else {
            const { login } = data;
            const { enable2FA = false, MFALoginSuccess = false } = login;
            if (!MFALoginSuccess) return resolve({ enable2FA });

            AuthService.setIdUsuario(login.usuario.idUsuario);
            AuthService.setLoginTime(new Date());
            if (recordame) {
              AuthService.setRecordame(true);
              AuthService.setLastUsername(username);
            } else {
              AuthService.setRecordame(false);
              AuthService.setLastUsername("");
            }
            client
              .mutate({
                mutation: AccessQraphQL.mutations.setUser,
                variables: { login: JSON.stringify(login) },
                refetchQueries: [`getRedirect`],
                awaitRefetchQueries: true
              })
              .then(() => {
                const privilegios = login.privilegios.map(obj => {
                  return obj.codigoPrivilegio;
                });

                AuthService.setRoles(privilegios);
                const url = window.location.hostname.replace("www.", "");
                client
                  .query({
                    query: AppQraphQL.queries.getPrograma,
                    errorPolicy: "all",
                    fetchPolicy: "cache-first",
                    variables: {
                      url
                    }
                  })
                  .then(({ data }) => {
                    const programa = getPrograma(data);
                    const { templateData } =
                      get(data, "miUsuario.segmento.template") ||
                      programa.template;
                    const theme = getTheme(templateData.theme);
                    updateTemplate({ theme, programa });
                    const miIdioma = get(data, "miUsuario.idioma");
                    const idiomas = programa.idiomas;
                    let idiomaDeUsuario =
                      miIdioma &&
                      idiomas.some(
                        ({ idioma: { _id } }) => _id === miIdioma._id
                      )
                        ? miIdioma
                        : undefined;
                    programa.segmentoDefault &&
                      TranslationService.initIdioma(
                        programa.segmentoDefault.idiomaDefault,
                        idiomaDeUsuario
                      );
                    const ultimaURL = AuthService.getUltimaURL();

                    if (tieneMFA) {
                      return resolve({
                        MFALoginSuccess,
                        ultimaURL
                      });
                    } else return resolve(ultimaURL);
                  });
              });
          }
        });
    });
  };
}
