// import generator from "./templateGenerator";

import defaultTemplate from "./defaultTemplate";
import { createMuiTheme } from "@material-ui/core/styles";
import WebFont from "webfontloader";
import AppQraphQL from "js/graphql/resolvers/programa.resolver";
import { get, flattenDeep, compact } from "lodash";
import AuthService from "js/utils/AuthService";
import { getUrl } from "js/utils";
import ReactGUA from "react-ga";
import ReactGA from "react-ga4";

export const getProgramaPublicInfo = ({
  getProgramaPublicInfo = undefined
}) => {
  try {
    return {
      ...getProgramaPublicInfo,
      template: {
        ...JSON.parse(getProgramaPublicInfo.template.template).templateDev
          .template,
        idTemplate: getProgramaPublicInfo.template.idTemplate
      }
    };
  } catch (error) {
    return defaultTemplate;
  }
};

export const getPrograma = ({ getPrograma = undefined }) => {
  try {
    return {
      ...getPrograma,
      template: {
        ...JSON.parse(getPrograma.template.template).templateDev.template,
        idTemplate: getPrograma.template.idTemplate
      }
    };
  } catch (error) {
    return defaultTemplate;
  }
};

export const getPrivateProgram = async ({ client, url }) => {
  await client
    .query({
      query: AppQraphQL.queries.getPrograma,
      errorPolicy: "all",
      fetchPolicy: "cache-first",
      variables: { url }
    })
    .then(res => {
      // if (loading || !data) return <SplashScreen />;
      return {
        template: get(res.data, "getPrograma.template.template", {}),
        isLogged: get(res.data, "isLogged", false)
      };
    });
};
export const getTheme = (theme = {}) => {
  let muiTheme = theme;

  return createMuiTheme({
    ...muiTheme,
    typography: {
      useNextVariants: true
    },
    container: {
      // maxWidth: 1200,
      margin: "0"
    }
  });
};

export const setWebFonts = fonts => {
  if (fonts && fonts.length !== 0) {
    WebFont.load({
      google: {
        families: fonts
      }
    });
  }
};

export const getFontsFromLanding = programa => {
  const contenidos = programa.template.landing.filas.map(
    fila => fila.contenidos
  );

  const fontUser = programa.template.header.user.css.fontFamily;

  const fontMenu = programa.template.header.menu.navbar.css.fontFamily;
  const destacados = programa.template.destacados.contenidos.map(
    contenido => contenido
  );
  const flattenContenidos = flattenDeep(contenidos);
  const flattenDestacados = flattenDeep(destacados);

  const basesFromSliders = flattenContenidos
    .filter(pC => pC !== null && pC.tipo === "slider")
    .map(pCs => pCs.bases);

  const basesFromLanding = flattenContenidos
    .filter(pC => pC !== null && pC.tipo === "base")
    .concat(flattenDeep(basesFromSliders));

  const basesFromSlidersDestacados = flattenDestacados
    .filter(pC => pC !== null && pC.tipo === "slider")
    .map(pCs => pCs.bases);

  const basesFromDestacados = flattenDestacados
    .filter(pC => pC !== null && pC.tipo === "base")
    .concat(flattenDeep(basesFromSlidersDestacados));

  const allBases = basesFromLanding.concat(basesFromDestacados);

  const fonts = allBases.reduce(
    (acc, baseFromLanding) =>
      acc.concat([
        get(baseFromLanding, "texto.css.fontFamily", ""),
        get(baseFromLanding, "subtexto.css.fontFamily", ""),
        get(basesFromDestacados, "texto.css.fontFamily", ""),
        get(basesFromDestacados, "subtexto.css.fontFamily", ""),
        fontUser,
        fontMenu
      ]),
    []
  );
  return compact([...new Set(fonts)]);
};

export const getSelectedFonts = (
  programa,
  { googleFont = ["Arial"], cucardas = [] }
) =>
  compact([
    ...getFontsFromLanding(programa),
    ...googleFont,
    ...cucardas.filter(c => c.fontFamily !== undefined).map(c => c.fontFamily)
  ]);

export const setPrivacy = esPublico => AuthService.setPrivacy(esPublico);
export const setHtmlExpectativa = htmlExpectativa =>
  AuthService.setHtmlExpectativa(htmlExpectativa);

export const setIdPrograma = idPrograma =>
  AuthService.setIdPrograma(idPrograma);

export const setTieneMFA = tieneMFA => AuthService.setTieneMFA(tieneMFA);

export const setPaisesPrograma = paises =>
  AuthService.setPaisesPrograma(paises);

export const setTabBrowser = ({ favicon, nombre }) => {
  const faviconLink =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  faviconLink.type = "image/x-icon";
  faviconLink.rel = "shortcut icon";
  faviconLink.href = getUrl(favicon);
  document.getElementsByTagName("head")[0].appendChild(faviconLink);
  document.title = nombre;
};

export const initGoogleAnalytics = trackingID =>
  trackingID && ReactGA.initialize(trackingID);

export const initGoogleUniversalAnalytics = trackingID =>
  trackingID && ReactGUA.initialize(trackingID);

export const initManifest = (name, template) => {
  const src = getUrl(get(template, "header.logoPrincipal.url", ""));
  const color = get(
    template,
    "templateData.theme.palette.primary.main",
    "#000"
  );
  const manifest = {
    name,
    short_name: name,
    start_url: "/",
    display: "standalone",
    theme_color: color,
    background_color: color,
    icons: ["48x48", "72x72", "96x96", "144x144", "168x168", "192x192"].map(
      sizes => ({ src, sizes })
    )
  };
  const stringManifest = JSON.stringify(manifest);
  const blob = new Blob([stringManifest], { type: "application/json" });
  const manifestURL = URL.createObjectURL(blob);
  document.querySelector("#manifest").setAttribute("href", manifestURL);
  const themeMeta = document.createElement("meta");
  themeMeta.name = "theme-color";
  themeMeta.content = color;
  document.getElementsByTagName("head")[0].appendChild(themeMeta);
};

export const canShowThis = idPrograma => {
  /**
   * Ad-hoc para no mostrar el botón "Cambiar clave" por lógica de negocio de
   * On Axion.
   * Ids de programas para ignorar el botón:
   * 108 - On Axion
   * 109 - Demo On Axion
   */
  const idsToIgnore = [108, 109];
  return idsToIgnore.indexOf(idPrograma) === -1;
};
// JSON.parse(generator).templateDev.template

export const addOpacityToHexColor = (hexColor, opacity = 0.2) => {
  // Eliminar el # si está presente
  hexColor = hexColor.replace("#", "");

  // Convertir los valores hexadecimales a decimales
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Retornar el color en formato rgba
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
