import React, { PureComponent } from "react";
import Form from "js/pages/Admin/Programa/Edit/MyForm.js";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import AppQraphQL from "js/graphql/resolvers/programa.resolver";
import RolQraphQL from "js/graphql/resolvers/rol.resolver";
import PerfilGraphQL from "js/graphql/resolvers/perfil.resolver";
import { removeTypename } from "js/utils/Helper";
import { keys } from "lodash";

const convertUnitToUnit = (value, unit, operator) => {
  const numValue = parseInt(value, 10);
  if (isNaN(numValue) || numValue < 0) return 0;

  // Define las conversiones por unidad
  const unitConversion = {
    MINUTOS: 60,
    HORAS: 3600,
    DIAS: 86400
  };

  // Valida si el operador es soportado
  const validOperators = {
    "*": (a, b) => a * b,
    "/": (a, b) => a / b,
    "+": (a, b) => a + b,
    "-": (a, b) => a - b
  };

  const operation = validOperators[operator];
  if (!operation) {
    throw new Error(`Operador no soportado: ${operator}`);
  }

  // Realiza la operación
  const conversionFactor = unitConversion[unit] || 1; // Por defecto es 1 si la unidad no es válida
  return operation(numValue, conversionFactor);
};
class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        esPublico: true,
        tieneMFA: false,
        unidadPeriodoValidacionMFA: "HORAS",
        catalogoPublico: true,
        beneficioPublico: false,
        conFormulario: true,
        conUsername: ""
      },
      errors: null,
      disabled: null,
      grupos: [],
      id: this.props.match.params.id,
      fechaLanzamiento: null,
      definicionesPerfil: []
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const {
      match: {
        params: { id, edit }
      }
    } = this.props;
    if (id !== "new") {
      let idPrograma = parseInt(id) || id;
      await client
        .query({
          query: AppQraphQL.queries.getProgramaById,
          fetchPolicy: "network-only",
          variables: {
            idPrograma
          }
        })
        .then(res => {
          let programa = res.data.getProgramas.itemsPagina[0];
          programa.modulosSeleccionados = {};
          if (programa && programa.modulos)
            programa.modulos
              .filter(i => i.estado === "ACTIVO")
              .map(i => (programa.modulosSeleccionados[i.nombreGrupo] = true));
          programa.indicadoresSeleccionados = {};
          if (programa && programa.indicadores)
            programa.indicadores
              .filter(i => i.estado === "ACTIVO")
              .map(i => (programa.indicadoresSeleccionados[i.kpi] = true));
          this.setState({
            initialValues: {
              ...programa,
              periodoValidacionMFA: convertUnitToUnit(
                programa.periodoValidacionMFA,
                programa.unidadPeriodoValidacionMFA,
                "/"
              )
            },
            disabled: !(edit === "edit"),
            fechaLanzamiento: programa.fechaLanzamiento
          });
        })
        .catch(e => {
          this.setState({ errors: e });
          return false;
        });
    }
    await client
      .query({
        query: RolQraphQL.queries.getModulos,
        fetchPolicy: "network-only"
      })
      .then(res => {
        this.setState({
          grupos: res.data.getModulos
        });
      });

    await client
      .query({
        query: PerfilGraphQL.queries.getDefinicionesPerfilById,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: parseInt(id) || id
        }
      })
      .then(res => {
        this.setState({
          definicionesPerfil: res.data.getDefinicionesPerfil
        });
      });
  }

  validateBeneficios(values, modulos) {
    let moduloFound = null;
    if (values.beneficioPublico === true) {
      moduloFound = modulos.find(modulo => {
        return (
          modulo.nombreGrupo === "Beneficios con cupón" &&
          modulo.estado === "ACTIVO"
        );
      });
      if (!moduloFound) {
        throw new Error(
          "Se requiere activación del módulo de Beneficios con cupón"
        );
      }
    }
  }

  validarCadenaDeEmails(cadena) {
    // Expresión regular para validar un correo electrónico
    const regexCorreo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Dividir la cadena por comas y eliminar espacios en blanco alrededor de cada correo
    const correos = cadena.split(",").map(correo => correo.trim());

    // Verificar cada correo utilizando la expresión regular
    const correosValidos = correos.every(correo => regexCorreo.test(correo));

    return correosValidos;
  }

  async submit(values) {
    const {
      client,
      history,
      match: {
        params: { id }
      }
    } = this.props;
    const modulos = keys(values.modulosSeleccionados).map(nombreGrupo => ({
      nombreGrupo,
      estado: values.modulosSeleccionados[nombreGrupo] ? "ACTIVO" : "INACTIVO"
    }));
    try {
      if (values.esNotificacionStockBajo && !values.emailsNotificacionStockBajo)
        throw new Error(
          "Se requiere ingresar al menos un correo electrónico para notificaciones de stock de seguridad"
        );

      if (
        values.emailsNotificacionStockBajo &&
        !this.validarCadenaDeEmails(values.emailsNotificacionStockBajo)
      )
        throw new Error(
          "La cadena de correos para notificaciones de stock de seguridad no es válida"
        );

      if (
        values.tieneMFA &&
        values.periodoValidacionMFA > 7 &&
        values.unidadPeriodoValidacionMFA === "DIAS"
      ) {
        throw new Error(
          "El periodo de validación de MFA no puede ser mayor a 7 días"
        );
      }

      this.validateBeneficios(values, modulos);
      delete values.modulosSeleccionados;
      values.modulos = modulos;
      const indicadores = keys(values.indicadoresSeleccionados).map(
        indicador => ({
          kpi: indicador,
          estado: values.indicadoresSeleccionados[indicador]
            ? "ACTIVO"
            : "INACTIVO"
        })
      );
      delete values.indicadoresSeleccionados;
      values.indicadores = indicadores;

      let formatValues = removeTypename(values);
      formatValues.tasaConversion =
        parseFloat(formatValues.tasaConversion) || formatValues.tasaConversion;
      formatValues.maximoMovimientoPuntos =
        parseFloat(formatValues.maximoMovimientoPuntos) ||
        formatValues.maximoMovimientoPuntos;
      formatValues.maximoMovimientoPlata =
        parseFloat(formatValues.maximoMovimientoPlata) ||
        formatValues.maximoMovimientoPlata;
      formatValues.indicadores = indicadores;
      await client
        .mutate({
          mutation:
            id === "new"
              ? AppQraphQL.queries.createPrograma
              : AppQraphQL.queries.updatePrograma,
          variables: {
            programa:
              id === "new"
                ? { ...formatValues }
                : {
                    idPrograma: parseInt(id, 10),
                    esPublico: values.esPublico,
                    nombre: values.nombre,
                    tipo: values.tipo,
                    estado: values.estado,
                    url: values.url,
                    tasaConversion:
                      parseFloat(values.tasaConversion) ||
                      values.tasaConversion,
                    razonSocial: values.razonSocial,
                    nombreFantasia: values.nombreFantasia,
                    cuit: values.cuit,
                    telefonoContacto: values.telefonoContacto,
                    emailContacto: values.emailContacto,
                    emailRemitente: values.emailRemitente,
                    diasParaVencimientoPuntos:
                      parseInt(values.diasParaVencimientoPuntos) ||
                      values.diasParaVencimientoPuntos ||
                      null,
                    diasParaNotificacionVencimientoPuntos:
                      parseInt(values.diasParaNotificacionVencimientoPuntos) ||
                      values.diasParaNotificacionVencimientoPuntos ||
                      null,
                    maximoMovimientoPuntos:
                      parseInt(values.maximoMovimientoPuntos) ||
                      values.maximoMovimientoPuntos,
                    maximoMovimientoPlata:
                      parseFloat(values.maximoMovimientoPlata) ||
                      values.maximoMovimientoPlata,
                    paises: removeTypename(values.paises),
                    monedaBase: values.monedaBase,
                    comentarios: values.comentarios,
                    modulos,
                    indicadores,
                    htmlExpectativa: values.htmlExpectativa,
                    urlContenidos: values.urlContenidos,
                    catalogoPublico: values.catalogoPublico,
                    beneficioPublico: values.beneficioPublico,
                    conFormulario: values.conFormulario,
                    conUsername: values.conUsername,
                    fechaLanzamiento: this.state.fechaLanzamiento,
                    codigoChatOnline: values.codigoChatOnline,
                    chatSinLogin: values.chatSinLogin,
                    conceptoCanje: values.conceptoCanje,
                    avisoCumpleJefe: values.avisoCumpleJefe,
                    avisoCumpleCompaneros: values.avisoCumpleCompaneros,
                    identificarCompanerosPor: values.identificarCompanerosPor,
                    cronExportacionUsuarios: values.cronExportacionUsuarios,
                    emailsExportacionUsuarios: values.emailsExportacionUsuarios,
                    esNotificacionStockBajo: values.esNotificacionStockBajo,
                    emailsNotificacionStockBajo:
                      values.emailsNotificacionStockBajo,
                    tieneMFA: values.tieneMFA,
                    periodoValidacionMFA: convertUnitToUnit(
                      parseInt(values.periodoValidacionMFA),
                      values.unidadPeriodoValidacionMFA,
                      "*"
                    ),
                    unidadPeriodoValidacionMFA:
                      values.unidadPeriodoValidacionMFA
                  }
          }
        })
        .then(res => {
          const message = "Programa grabado correctamente";
          this.props.openNotification(message);
          const idPrograma =
            (res.data.updatePrograma && res.data.updatePrograma.idPrograma) ||
            (res.data.createPrograma && res.data.createPrograma.idPrograma);
          history.push(`/admin/programa/${idPrograma}/gestion`);
        })
        .catch(e => {
          this.setState({
            ...this.state,
            errors: e
          });
          return false;
        });
    } catch (e) {
      this.setState({
        ...this.state,
        errors: e
      });
      return false;
    }
  }
  handleChangeFecha = key => date => {
    this.setState({
      [key]: date.toISOString()
    });
  };
  render() {
    return (
      <Form
        id={this.state.id}
        errors={this.state.errors}
        disabled={this.state.disabled}
        initialValues={this.state.initialValues}
        onSubmit={this.submit.bind(this)}
        grupos={this.state.grupos}
        definicionesPerfil={this.state.definicionesPerfil}
        fechaLanzamiento={this.state.fechaLanzamiento}
        handleChangeFecha={this.handleChangeFecha}
      />
    );
  }
}

export default withApollo(withRouter(Edit));
