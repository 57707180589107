import React, { Component } from "react";
import PropTypes from "prop-types";
import { css } from "emotion";
import get from "lodash/get";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormControl from "@material-ui/core/FormControl";
import { Link } from "react-router-dom";
import { ApolloConsumer } from "react-apollo";
import IdiomasList from "js/components/Shared/IdiomasList";
import AuthService from "js/utils/AuthService";
import ErrorList from "js/components/Shared/ErrorList";
import { _t } from "js/utils/TranslationService";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ssoLogin } from "js/utils";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { getBooleanLinkLabel } from "js/components/Shared/Form/fieldGenerator";
import AppQraphQL from "js/graphql/resolvers/programa.resolver";
import { getPrograma } from "./../../App/utils.js";
import client from "./../../App/client.graphql.js";

const getSignInLabel = (label, link) => {
  // Generando un modelo igual al model de datos de perfil ya que la funcionalidad esta hecha en la función getBooleanLinkLabel
  const field = {
    label: label,
    value: [link]
  };
  return getBooleanLinkLabel(field);
};
export class Signin extends Component {
  constructor(props) {
    super(props);
    const recordame = AuthService.getRecordame();
    const lastUsername = AuthService.getLastUsername();
    this.state = {
      usuario: recordame && lastUsername ? lastUsername : "",
      password: "",
      MFACode: null,
      showPassword: false,
      errors: null,
      loading: false,
      recordame: recordame,
      tieneMFA: JSON.parse(localStorage.getItem("tieneMFA")),
      tieneTerminos: get(
        props,
        "programa.template.access.signin.adicionales.firmaTerminos",
        false
      ),
      aceptarTerminos: false,
      enable2FA: false,
      countdown: null
    };
  }

  handleChange = prop => event => {
    this.setState({ errors: null, [prop]: event.target.value });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleRecordame = () => {
    this.setState(state => ({ recordame: !state.recordame }));
  };

  handleAceptarTerminos = () => {
    this.setState(state => ({ aceptarTerminos: !state.aceptarTerminos }));
  };

  handleSetLinksToTrack = async () => {
    const queryOption = AppQraphQL.queries.getPrograma;
    const url = window.location.hostname.replace("www.", "");
    await client
      .query({
        query: queryOption,
        variables: { url },
        errorPolicy: "all",
        fetchPolicy: "cache-first"
      })
      .then(({ data }) => {
        let programa = getPrograma(data);
        if (programa && programa.modulos) {
          programa.modulos.forEach(modulo => {
            if (
              modulo.nombreGrupo.toLowerCase() === "tracking de links" &&
              modulo.estado === "ACTIVO"
            ) {
              AuthService.setTrackLinks(true);
              AuthService.setLinksToTrack(programa.linksToTrack);
            }
          });
        }
      });
  };

  onSubmit = async ({ client, state, programa, previousLocation }) => {
    const { history, updateTemplate } = this.props;
    const {
      tieneMFA,
      usuario: username,
      password,
      MFACode,
      loading,
      recordame,
      tieneTerminos,
      aceptarTerminos
    } = state;
    if (tieneTerminos && !aceptarTerminos) {
      this.setState({
        errors: {
          message:
            "Tiene que aceptar los términos y condiciones para poder ingresar"
        }
      });
      return;
    }

    if (loading || !username || !password) return;

    const usernameTrimmed = username.trim();

    const idPrograma = programa.idPrograma;

    this.setState({ loading: true });

    if (!tieneMFA) {
      AuthService.login(
        usernameTrimmed,
        password,
        MFACode,
        idPrograma,
        updateTemplate,
        recordame,
        tieneMFA
      )
        .then(ultimaURL => {
          history.push(ultimaURL || "/");
        })
        .then(res => {
          this.handleSetLinksToTrack();
        })
        .catch(errors => this.setState({ errors: errors[0], password: "" }))
        .finally(() => this.setState({ loading: false }));
    } else {
      AuthService.login(
        usernameTrimmed,
        password,
        MFACode,
        idPrograma,
        updateTemplate,
        recordame,
        tieneMFA
      )
        .then(({ enable2FA, ultimaURL, MFALoginSuccess }) => {
          if (MFALoginSuccess) {
            history.push(ultimaURL || "/");
          } else {
            const { countdown } = this.state;
            if (countdown) {
              this.setState({
                enable2FA,
                loading: false,
                countdown,
                countdownClock: 120
              });
            }
            this.setState({
              enable2FA,
              loading: false,
              countdown: 300,
              countdownClock: 120
            });
            // Iniciar cuenta regresiva de 5 minutos
            const interval = setInterval(() => {
              this.setState(prevState => {
                if (prevState.countdown <= 1) {
                  clearInterval(interval);
                  return { enable2FA: false, countdown: 0, password: "" };
                }
                return { countdown: prevState.countdown - 1 };
              });
            }, 1000);
            const interval2 = setInterval(() => {
              this.setState(prevState => {
                if (prevState.countdownClock <= 1) {
                  clearInterval(interval2);
                  return { countdownClock: 0 };
                }
                return { countdownClock: prevState.countdownClock - 1 };
              });
            }, 1000);
          }
        })
        .catch(errors => this.setState({ ...this.state, errors: errors[0] }))
        .finally(() => this.setState({ loading: false }));
    }
  };

  render() {
    const { classes, programa, location, logo } = this.props;
    const { access } = programa.template;
    const { esPublico, conFormulario } = programa;
    const {
      usuario,
      password,
      loading,
      errors,
      recordame,
      tieneTerminos,
      aceptarTerminos,
      enable2FA,
      MFACode
    } = this.state;
    // `currentLocation` might not be available here.
    // This particular location state is set from the logout method in the UserMenu component
    const previousLocation = get(location, "state.currentLocation", "");
    if (programa.idPrograma === 7 || programa.idPrograma === 15) ssoLogin();
    const labelTerminos = get(
      programa,
      "template.access.signin.adicionales.textoTerminos",
      ""
    );
    const linkTerminos = get(
      programa,
      "template.access.signin.adicionales.linkTermios",
      ""
    );
    return (
      <Paper className={classes.root} elevation={1}>
        <div className={classes.idiomaPosition}>
          <IdiomasList />
        </div>
        <img alt="Logo" src={logo} className={classes.logo} />
        <ApolloConsumer>
          {client =>
            enable2FA ? (
              <form
                onSubmit={event => {
                  event.preventDefault();
                  this.onSubmit({
                    client,
                    state: this.state,
                    programa,
                    previousLocation
                  });
                }}
              >
                <Grid container direction="column">
                  <Typography variant="h6" gutterBottom>
                    {_t("Ingresá el código de autenticación")}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ whiteSpace: "pre-line" }}
                    gutterBottom
                  >
                    {_t(
                      "Revisá tu correo y colocá los 6 (seis) dígitos a continuación"
                    )}
                  </Typography>
                  <FormControl>
                    <Input
                      placeholder={_t("Código")}
                      id="MFACode"
                      autoComplete="MFACode"
                      value={MFACode ? MFACode : " "}
                      onChange={this.handleChange("MFACode")}
                    />
                  </FormControl>
                  <Grid
                    item
                    xs={12}
                    container
                    justify="flex-end"
                    alignItems="center"
                    style={{ marginTop: "1%" }}
                  >
                    <CircularProgress
                      variant="static"
                      value={
                        Math.floor(
                          ((this.state.countdownClock / 120) * 100) / 5
                        ) * 5
                      }
                      size={20}
                      thickness={20}
                      color="primary"
                      style={{
                        transform: "rotate(270deg)",
                        marginRight: "8px"
                      }}
                    />
                    <Typography variant="subtitle1">
                      {`Tiempo restante: ${Math.floor(
                        this.state.countdownClock / 60
                      )}:${(this.state.countdownClock % 60)
                        .toString()
                        .padStart(2, "0")}`}
                    </Typography>
                  </Grid>
                </Grid>
                <ErrorList errors={errors} belowInput />
                <Grid item xs={12} container justify="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    color="primary"
                    className={classes.button}
                  >
                    {!loading &&
                      _t(
                        this.state.countdownClock === 0
                          ? "Reenviar código"
                          : "Verificar código"
                      )}
                    {loading && (
                      <CircularProgress
                        className={classes.progress}
                        size={20}
                        color="inherit"
                      />
                    )}
                  </Button>
                </Grid>
              </form>
            ) : (
              <form
                onSubmit={event => {
                  event.preventDefault();
                  this.onSubmit({
                    client,
                    state: this.state,
                    programa,
                    previousLocation
                  });
                }}
              >
                <Grid container direction="column">
                  <Typography variant="h6" gutterBottom>
                    {access.signin.titulo}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ whiteSpace: "pre-line" }}
                    gutterBottom
                  >
                    {access.signin.descripcion}
                  </Typography>
                  <FormControl
                    className={css(
                      classes.margin,
                      classes.withoutLabel,
                      classes.textField
                    )}
                  >
                    <Input
                      placeholder={_t("Usuario")}
                      id="adornment-usuario"
                      value={usuario}
                      onChange={this.handleChange("usuario")}
                    />
                  </FormControl>
                  <FormControl
                    className={css(classes.margin, classes.textField)}
                  >
                    <Input
                      placeholder={_t("Clave")}
                      id="adornment-password"
                      autoComplete="new-password"
                      type={this.state.showPassword ? "text" : "password"}
                      value={password}
                      onChange={this.handleChange("password")}
                      endAdornment={
                        <InputAdornment position="end" disableTypography={true}>
                          <IconButton
                            aria-label={`${
                              this.state.showPassword
                                ? _t("Ocultar clave")
                                : _t("Mostrar clave")
                            } `}
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                          >
                            {this.state.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={recordame}
                          onChange={this.handleRecordame}
                          value="recordame"
                        />
                      }
                      label={_t("Recordame")}
                    />
                  </FormControl>
                  {tieneTerminos && (
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={aceptarTerminos}
                            onChange={this.handleAceptarTerminos}
                            value="aceptarTerminos"
                          />
                        }
                        label={getSignInLabel(labelTerminos, linkTerminos)}
                      />
                    </FormControl>
                  )}
                </Grid>
                <ErrorList errors={errors} belowInput />
                <Grid item xs={12} container justify="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={!usuario || !password}
                    color="primary"
                    className={classes.button}
                  >
                    {!loading && _t("Iniciar sesión")}
                    {loading && (
                      <CircularProgress
                        className={classes.progress}
                        size={20}
                        color="inherit"
                      />
                    )}
                  </Button>
                </Grid>
              </form>
            )
          }
        </ApolloConsumer>
        {conFormulario &&
          !enable2FA && (
            <Typography variant="caption" gutterBottom align="right">
              <Link to="/signup">
                {_t("Si no estás registrado ingresa aquí")}
              </Link>
            </Typography>
          )}
        {esPublico && (
          <Typography variant="caption" gutterBottom align="right">
            <Link to="/">{_t("Volver al inicio")}</Link>
          </Typography>
        )}
        {!enable2FA && (
          <Grid container justify="space-between">
            <Typography variant="caption" gutterBottom align="left">
              <Link to="/restoreUser">{_t("Recuperar usuario")}</Link>
            </Typography>
            <Typography variant="caption" gutterBottom align="right">
              <Link to="/restorePassword">{_t("Recuperar clave")}</Link>
            </Typography>
          </Grid>
        )}
      </Paper>
    );
  }
}

Signin.propTypes = {
  programa: PropTypes.object,
  updateTemplate: PropTypes.func
};

Signin.defaultProps = {
  programa: {},
  open: false,
  updateTemplate: () => undefined
};

export default Signin;
