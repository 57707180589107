import gql from "graphql-tag";

export const signin = gql`
  query Signin(
    $username: String!
    $password: String!
    $idPrograma: Int!
    $tieneMFA: Boolean
    $MFACode: String
  ) {
    login(
      username: $username
      password: $password
      idPrograma: $idPrograma
      tieneMFA: $tieneMFA
      MFACode: $MFACode
    ) {
      usuario {
        idUsuario
        estado
        username
        roles {
          idRol
          nombre
        }
      }
      usoClaveDeUnUso
      privilegios {
        codigoPrivilegio
      }
      enable2FA
      MFALoginSuccess
    }
  }
`;

export const writeUser = (_, { user }, { cache }) => {
  const userParsed = JSON.parse(user);
  const newUserData = {
    isLogged: true,
    userData: {
      userName: userParsed.usuario.username,
      privilegios: userParsed.privilegios,
      __typename: "UserData"
    },
    __typename: "User"
  };
  const data = { user: newUserData };
  cache.writeData({ data });
  return null;
};

export const setUser = gql`
  mutation setUser($login: String!) {
    writeUser(user: $login) @client {
      userData
    }
  }
`;

export const recuperarClave = gql`
  mutation recuperarClave($usuario: String!, $idPrograma: Int!) {
    recuperarClave(username: $usuario, idPrograma: $idPrograma) {
      email
    }
  }
`;
export const recuperarUsuario = gql`
  mutation recuperarUsuario($email: String!, $idPrograma: Int!) {
    recuperarUsuario(email: $email, idPrograma: $idPrograma)
  }
`;

export const activarUsuario = gql`
  mutation activarUsuario($token: String!) {
    activarUsuario(token: $token)
  }
`;

export const userDefaults = {
  isLogged: false,
  userData: {
    userName: "pablo",
    privilegios: "",
    __typename: "UserData"
  },
  __typename: "User"
};

export default {
  mutations: {
    setUser,
    writeUser,
    recuperarClave,
    recuperarUsuario,
    activarUsuario
  },
  queries: {
    signin
  },
  defaults: { userDefaults }
};
