import { _t } from "js/utils/TranslationService";
import React, { Fragment } from "react";
import { compose } from "recompose";
import { ItemLine } from "./ItemLine";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withNotificationContext } from "js/notification-context";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Slide,
  Typography,
  Divider,
  CircularProgress,
  DialogContent,
  Grid
} from "@material-ui/core";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import AppQraphQL from "js/graphql/resolvers/ventaRetail.resolver";
import _ from "lodash";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class RevertDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      onSubmit: false,
      variables: {},
      errors: undefined
    };
  }

  async componentDidMount() {
    const { client, query, item } = this.props;

    try {
      this.setState({ loading: true });

      let movimiento = await client.query({
        query: query,
        fetchPolicy: "network-only",
        variables: {
          orderBy: "fecha",
          idMovimiento: item.idMovimiento,
          operacion: "CREDITO",
          tipoOperacion: ["VENTA_RETAIL"]
        }
      });

      if (_.isNil(movimiento)) throw new Error("No se encontro el movimiento");

      const {
        data: {
          getMovimientosUsuarios: {
            itemsPagina: [movimientoFactura]
          }
        }
      } = movimiento;

      const {
        puntos,
        cuentaCorriente: { usuario }
      } = movimientoFactura;

      const variables = {
        puntos: {
          idUsuario: usuario.idUsuario,
          puntosAsignados: puntos,
          concepto:
            "Anulación de Acreditación de Puntos por Factura " +
            item.numeroFactura
        },
        operacion: "DEBITO"
      };

      this.setState({ loading: false, variables });
    } catch (error) {
      console.log(error.message);
      this.setState({ loading: false, errors: error.message });
    }
  }

  sendValues = async () => {
    const { client, mutation, item } = this.props;
    const { variables, onSubmit } = this.state;

    try {
      this.setState({ onSubmit: true });

      if (_.isNil(variables)) throw new Error("No se encontro el movimiento");

      const newMovimiento = await client.mutate({
        mutation,
        variables
      });
      if (_.isNil(newMovimiento))
        throw new Error("No se pudo crear el nuevo movimiento");

      const updateEstadoVentaRetail = await client.mutate({
        mutation: AppQraphQL.mutations.updateEstadoVentaRetail,
        options: { fetchPolicy: "network-only" },
        variables: {
          id: item._id,
          estado: "ANULADA"
        }
      });

      if (_.isNil(updateEstadoVentaRetail))
        throw new Error(
          "Ocurrio un error al actualizar el estado de la venta retail"
        );

      this.props.openNotification(
        "Anulación de acreditación finalizada con éxito"
      );
      this.setState({ onSubmit: false });
      this.props.onTableChange();
      this.props.handleClose();
    } catch (error) {
      this.setState({ onSubmit: false });
      console.log(error.message);
      this.props.openNotification(
        "Hubo un error al ejecutar la anulación de acreditación: ",
        error.message
      );
    }
  };

  render() {
    const { handleClose, classes, item } = this.props;
    const { loading, onSubmit } = this.state;

    return (
      <Fragment>
        <Dialog
          open
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth={true}
        >
          {loading ? (
            <DialogContent>
              <Grid direction="row" justify="center" alignItems="center">
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <CircularProgress
                    className={classes.progress}
                    color="primary"
                  />
                </Grid>
              </Grid>
            </DialogContent>
          ) : (
            <>
              <DialogTitle id="alert-dialog-slide-title">
                <Typography variant="h6">
                  {"Confirma la información"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{ margin: "0.50em 0 0 0" }}
                >
                  {
                    "Vas a revertir el movimiento generado por la siguiente factura: "
                  }
                </Typography>
              </DialogTitle>
              <DialogContent>
                <ItemLine
                  fixed={_t("N de Factura")}
                  value={item ? item.numeroFactura : ""}
                />
                <ItemLine
                  fixed={_t("Canal")}
                  value={item && item.canal ? item.canal.nombre : ""}
                />
                <ItemLine
                  fixed={_t("Fecha")}
                  value={new Date(item.fechaCreacion).toLocaleDateString()}
                />
                <ItemLine
                  fixed={_t("Puntos Acreditados")}
                  value={item ? `${item.total}` : ""}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  color="primary"
                >
                  Cancelar
                </Button>
                <Button
                  onClick={this.sendValues}
                  variant="contained"
                  color="primary"
                  disabled={onSubmit}
                  size="large"
                >
                  {onSubmit ? <CircularProgress size={24} /> : _t("Revertir")}
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </Fragment>
    );
  }
}

const enhance = compose(
  withApollo,
  withNotificationContext,
  withRouter,
  withStyles(styles)
);

export default enhance(RevertDialog);
